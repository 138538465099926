<template>
  <div class="class-list">
    <GlobalInfoBar
      title="二维码生成&分配"
      content="管理系统二维码的生成、分配激活、发货等。"
      contentOn="流程: 生成二维码（确认生成数量） => 交付厂家打印制作（确认起始、截止号码） => 二维码到货后分配商家（导入或添加需要分配的商家） => 按已分配的商家激活二维码（激活时扫描每圈二维码第一张）=> 邮寄二维码给商家"
    />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <CreatDistributeFrom @setSuccess="setSuccess"/>
      </div>

      <CreatDistributeTable ref="CreatDistributeTable"/>
    </GlobalChunk>
  </div>
</template>

<script>
import CreatDistributeFrom from "./Form";
import CreatDistributeTable from "./Table";
export default {
  name: "class-list",
  components: { CreatDistributeFrom, CreatDistributeTable },
  data() {
    return {};
  },
  methods: {
    setSuccess(){
      this.$refs.CreatDistributeTable.handleCurrentChange(1)
    }
  },
};
</script>

<style>
</style>