<template>
    <div class="sort-box" @click="handleSort">
		<div class="sort up" :class="sortActive === 'asc' ? 'active' : ''"></div>
		<div class="sort down" :class="sortActive === 'desc' ? 'active' : ''"></div>
	</div>
</template>

<script>
    export default {
        name: 'jSort',

        data () {
            return {
				sortActiveData: this.sortActive
				// sortActive: ''
			}
        },

		props: {
			sortActive: {
				type: String,
				default: ''
			}
		},

        methods: {
			handleSort () {
				(this.sortActive === 'desc' || this.sortActive === '') ? this.sortActiveData = 'asc' : this.sortActiveData = 'desc'
				this.$emit('handleSort', this.sortActiveData)
			}
		}
    }
</script>

<style lang='scss' scoped>
	@import '~@/assets/scss/variables';

	.sort-box {
		cursor: pointer;
	}
	.sort {
		width: 0;
		height: 0;
		border-width: 5px;
		border-style: solid;
		border-color: #dcdfe6 transparent transparent transparent;
		&.up {
			transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			margin-bottom: 5px;
		}
		&.active {
			border-color: $--color-primary transparent transparent transparent;
		}
	}
</style>
