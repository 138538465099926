<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="批次号" slot="batchCode" style="margin:0">
        <el-input v-model="batchCode" clearable placeholder="输入批次号查询"></el-input>
      </el-form-item>
    <div slot="AddSlot" >
      <el-button size="small" type="warning" @click="creatQrcode">生成二维码</el-button>
    </div>
    </GlobalForm>
    <el-dialog
      title="生成二维码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div class="creat-title">
        <span>说明：二维码生成的最大数量为 <span class="tip">{{otherInfo.maxValue}}</span></span>
      </div>
      <div class="creat-form">
        <span>生成数量：</span>
        <el-input placeholder="请输入生成数量" oninput="value=value.replace(/[^0-9]/g,'')" style="width:390px" v-model="num">
          <template slot="append">份*500</template>
        </el-input>
        <span class="tip">请输入本次生成的份数 （例如: 输入500则生成500*500数量）</span>
      </div>
      <div class="creat-information">
        <span>上次生成的二维码数据为: {{otherInfo.intervalStart}} ~ {{otherInfo.intervalEnd}}</span>
      </div> 
      <span slot="footer" class="creat-footer">
        <el-button @click="handleDialogClosed">取 消</el-button>
        <el-button type="primary" @click="handleSuccess">确定生成</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from 'moment'

export default {
  name: "Form",
  data() {
    return {
      initData: {
        entryTime: [moment().day(moment().day()).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      dialogVisible: false,
      num: '',
      dataForm: {},
      otherInfo: {},
      batchCode: "",
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "生成时间",
          placeholder: "请选择生成时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        { slotName: 'batchCode' },
      ],
      SeachParams: null
    };
  },
  created(){
    this.batchCode = this.$route.query.batchCode || ""
    this.handleConfirm(this.initData)
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {}
      console.log(this.batchCode);
      if(data){
        SeachParams = {
          batchCodecd: this.batchCode || "",
          startTimecd: data.entryTime? moment(data.entryTime[0]).format("x"):"",
          endTimecd: data.entryTime? moment(data.entryTime[1]).format("x"):"",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }
      this.SeachParams = SeachParams
      
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    creatQrcode () {
      this.dialogVisible = true;
      this.barCodeOtherInfo()
    },
    // 关闭生成二维码弹窗
    handleDialogClosed() {
      this.dialogVisible = false;
    },
    // 确认生成二维码
    handleSuccess () {
      if(this.num > 100){
        this.$message.error('一次最多生成100份')
        return
      }
      const loading = this.$loading({
        lock: true,
        target: '.el-dialog',
        text: '正在生成二维码，请稍后...'
      });
      _api.generateQRCode({num:this.num}).then(res => {
        if(res.code === 1){
          this.$message.success("恭喜！二维码已全部生成成功");
          this.dialogVisible = false
          this.num = ""
          const SeachParams = {
            pageNum: 1,
            pageSize: 10,
          }
          loading.close();
          this.$emit('setSuccess')
        }
      })
    },
    // 二维码生成其他信息
    barCodeOtherInfo() {
      _api.barCodeOtherInfo().then(res => {
        if(res.code === 1){
          this.otherInfo = res.data
        }
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .creat-title{
    // height: 40px;
    .tip{
      color: #ff687b;
    }
  }
  .creat-form{
    position: relative;
    padding: 30px 0;
    display: flex;
    align-items: center;
    span{
      font-weight: bold;
    }
    /deep/.el-input-group__append{
      color: #333;
    }
    .tip{
      position: absolute;
      bottom: 10px;
      left: 70px;
      font-size: 12px;
      font-weight: initial;
      color: #999;
    }
  }
  .creat-information{
    margin-top: 10px;
    span{
      color: #ffaf29;
    }
  }
}
</style>
