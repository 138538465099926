<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="已分配商家" slot="disCompanyNum" align="center">
        <template slot-scope="{ row }">
          <el-button
            v-if="row.disCompanyNum"
            type="text"
            class="text-btn"
            @click="handleShowUser(row)"
            >{{ row.disCompanyNum }}</el-button
          >
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        label="印刷完成时间"
        slot="printingDate"
        align="center"
      >
        <template slot-scope="{ row }">
          <div>{{ row.printingDate }}</div>
          <el-button
            v-if="row.printingDate"
            type="text"
            class="time-btn"
            @click="editTime(row)"
            >修改
            <el-date-picker
              v-model="printingDate"
              type="date"
              :picker-options="pickerOptions"
              value-format="timestamp"
              @change="completionTimeFun"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-button>
          <el-button v-else type="text" class="time-btn" @click="editTime(row)">
            <el-date-picker
              v-model="printingDate"
              type="date"
              :picker-options="pickerOptions"
              value-format="timestamp"
              @change="completionTimeFun"
              placeholder="选择日期"
            >
            </el-date-picker>
            添加</el-button
          >
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" :disabled="row.state=='01'" type="primary" @click="distributionBtn(row)"
            >分配激活</el-button
          >
          <el-button size="mini" type="primary" @click="handleBtn(row)">{{
            row.remark ? "修改备注" : "添加备注"
          }}</el-button>
        </template>
      </el-table-column>
    </GlobalTable>

    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="handleDialogClosed"
    >
      <el-input
        type="textarea"
        v-model="remark"
        maxlength="100"
        show-word-limit
        :rows="2"
        placeholder="请输入备注说明（限100字内）"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click.native="handleSuccess"
          >确定{{ remark ? "修改" : "添加" }}</el-button
        >
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="分配激活"
      class="activa-dialog"
      :visible.sync="activaVisible"
      :close-on-click-modal="false"
      width="1100px"
      @closed="handleClose"
    >
      <div class="explain">
        <span
          >说明:
          分配时一个商家绑定多份二维码标签（绑定完成请仔细核对后再确认分配）</span
        >
        <div class="btn-group">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-plus"
            @click.native="addBusiness"
            >添加商家</el-button
          >
          <el-button type="primary" size="mini" @click.native="importBusiness"
            >批量导入商家</el-button
          >
        </div>
      </div>
      <div class="activa-table">
        <div class="activa-title">
          <div class="title1">待分配激活商家</div>
          <div class="title2">
            说明:
            每份为500张（1圈二维码标签纸），分配时请注意核对商家及对应数量
          </div>
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="activaloading"
        element-loading-text="正在分配二维码，请稍后..."
        :columns="activaColumns"
        :maxHeight="500"
        :data="assignedMerchantList"
        :isPagination="false"
      >
        <el-table-column
          label="本次分配份数"
          slot="distributionNum"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.distributionNum">{{ row.distributionNum }}</span>
            <span v-else style="color:#999999">绑定后显示</span>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="激活绑定"
          slot="operation"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button size="mini" v-if="!row.distributionNum" type="text" @click="bindingBtn(row)">绑定</el-button>
            <el-button size="mini" v-else type="text" @click="bindingBtn(row)">修改绑定</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click.native="handleSuccess"
          >确定分配</el-button
        >
        <el-button @click.native="distributionClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加门店商家"
      class="add-business-dialog"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
      width="1200px"
      @closed="addClosed"
    >
      <div class="explain">
        <span style="color:#FF687B"
          >说明:
          输入框'剩余二维码'中输入数字后，例如输入100，点击‘查询’则查询‘门店平均剩余’二维码数量小于100的商家</span
        >
      </div>
      <div class="operation">
        <div class="search">
          <div class="search-item">
            <span style="width: 110px">商家名称:</span>
            <el-input
              class="search-input"
              v-model="companyName"
              placeholder="输入商家名称查询"
            ></el-input>
          </div>
          <div>
            <el-button type="primary" size="mini" @click="searchBusiness"
              >查询</el-button
            >
          </div>
        </div>
        <div>
          <el-radio-group
            class="selectSort"
            v-model="filterFormData.sortType"
            @change="changeSort"
          >
            <el-radio label="time">
              最近分配时间排序
              <j-sort
                @handleSort="handleSort"
                style="margin-left: 10px"
                :sortActive="selectSortData.timeSort"
              ></j-sort>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <GlobalTable
        ref="multipleTable"
        v-loading="businessLoading"
        :columns="businessColumns"
        :data="this.merchantList"
        :currentPage="merchantPages.pageNum"
        :total="merchantPages.total"
        :isSelection="true"
        judgeValue="companyId"
        @handleCurrentChange="barCodeCompanyList"
        @handleSelect="handleSelect"
      >
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click.native="handleSuccess"
          >确定添加</el-button
        >
        <el-button @click.native="addClosed">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="批量导入商家"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      class="import-business-dialog"
      width="500px"
      @closed="importClosed"
    >
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="marginright: 12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content"
            >请上传.xls或.xlsx格式的excel文件，大小10M内</span
          >
        </div>
        <div class="step2-content">
          <el-upload
            ref="upload"
            :limit="1"
            :headers="headers"
            :auto-upload="false"
            :http-request="uploadFile"
            :before-upload="onUpload"
            accept=".xls,.xlsx"
            action="customize"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="importSuccess">确定</el-button>
        <el-button @click.native="importClosed">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="已分配商家"
      class="allocated-business-dialog"
      :visible.sync="allocatedVisible"
      :close-on-click-modal="false"
      width="1200px"
      @closed="allocatedClose"
    >
      <div class="operation">
        <div class="search">
          <div class="search-item">
            <span style="width: 110px">商家名称:</span>
            <el-input
              class="search-input"
              v-model="companyName"
              placeholder="输入商家名称查询"
            ></el-input>
          </div>
          <div>
            <el-button type="primary" size="mini" @click="searchBusiness"
              >查询</el-button
            >
          </div>
        </div>
        <el-radio-group
            class="selectSort"
            v-model="filterFormData.sortType"
            @change="changeSort"
          >
            <el-radio label="time">
              平均剩余数量排序
              <j-sort
                @handleSort="handleSort"
                style="margin-left: 10px"
                :sortActive="selectSortData.timeSort"
              ></j-sort>
            </el-radio>
            <el-radio label="num">
              最近分配时间
              <j-sort
                @handleSort="handleSort"
                style="margin-left: 10px"
                :sortActive="selectSortData.numSort"
              ></j-sort>
            </el-radio>
          </el-radio-group>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="allocatedLoading"
        :maxHeight="500"
        :columns="allocatedColumns"
        :data="assignedMerchantList"
        :isPagination="false"
        @handleCurrentChange="handleCurrentChange"
      >
      </GlobalTable>
    </el-dialog>
    <el-dialog
      title=" 激活绑定二维码"
      class="binding-dialog"
      :visible.sync="bindingVisible"
      :close-on-click-modal="false"
      width="556px"
      @closed="bindingClose"
    >
      <div class="binding-num">
        <span>本次分配份数:</span>
        <!-- <el-input-number v-model="number" controls @change="handleChange" size="small" :min="1" label="描述文字"></el-input-number> -->
        <el-input v-model="number" disabled>
          <el-button slot="append" icon="el-icon-plus" @click="plusNum"></el-button>
        </el-input>
      </div>
      <div class="tip">
        <div>操作提示: 请将扫码枪对准已印刷的二维码第一张扫码，或输入后查询。</div>
        <div style="padding-left:64px">(若使用扫码枪，请将输入法调至英文输入法。)</div>
      </div>
      <!-- value=value.replace(/[^0-9]/g,'') -->
      <div class="binding-input">
        <div class="binding-input-item" v-for="(item,index) in bindingList" :key="index">
          <div class="binding-input-flex">
            <el-input class="qcInput" placeholder="扫码或输入二维码数字" maxlength="10" oninput="value=value.replace(/[^0-9]/g,'')" @input="(e)=>{qcInput(e,item,index)}" v-model="item.value">
              <el-button slot="suffix" size="small" type="warning" @click="check(item,index)">查询</el-button>
            </el-input>
            <img src="@/assets/images/icon_delete.png" alt="" class="binding-reduce" @click="bindingReduce(index)">
          </div>
          <span :class="['checks',(item.isSuccess=='01'?'success':(item.isSuccess=='00'?'fail':''))]">{{item.tip}}
            <span v-if="item.isSuccess=='01'">验证成功 <span style="color:#0981FF" v-if="item.volumeInfo">批次号：{{item.volumeInfo.batchCode+' 存在于号段：'+item.volumeInfo.intervalStart+' ~ '+item.volumeInfo.intervalEnd}}</span></span>
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="bindingSuccess">绑定</el-button>
        <el-button @click="bindingClosed">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import JSort from '@/components/sort/jSort'
import Moment from 'moment'
import { encryptByDES} from '@/utils/3DES'
export default {
  name: "Table",
  components:{JSort},
  mixins: [MinXinRequest],
  data() {
    return {
      headers:{
        'ACCESS_TOKEN': localStorage.getItem('token'),
        'channel':'platformPc',
        'TOKEN':''
      },
      loading: false,
      activaloading: false,
      allocatedLoading: false,
      businessLoading: false,
      seachDataList: [],
      assignedMerchantList:[],
      merchantList:[],
      batchCodecd: "",
      volumeNo: [],
      bindingList:[
        {
          value:"",
          tip:"未验证",
          isSuccess:null,
        }
      ],
      number: 1,
      remark: "",
      ResidentEnable: {
        id: "",
        isEnable: "",
      },
      pageNum: 1,
      merchantPageNum: 1,
      merchantPages: {},
      //弹窗
      dialogVisible: false, // 备注弹框的出现与否
      allocatedVisible: false, // 已分配商家弹窗显示与否
      activaVisible: false, // 分配激活弹窗的出现与否
      addVisible: false, // 添加门店商家弹窗出现与否
      importVisible: false, // 批量导入商家弹窗显示与否
      bindingVisible: false, // 绑定激活二维码弹窗现实与否
      dialogTitle: "", // 标题
      dialogTip: "", // 弹窗提示内容
      filterFormData:{
        sortType: 'time',
      },
      selectSortData: {
        timeSort: '',
        numSort: ''
      },
      
      searchForm: {
        name: ""
      },
      batchCode: "",
      companyName: "",
      printingDate: "",
      companyIdArr: [],
      companyId:"",
      pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now();//禁止选择以后的时间
				},
			},
      tableColumns: [
        { label: "批次号", prop: "batchCode" },
        { label: "生成总量", prop: "totalExpress" },
        { label: "生成时间", prop: "createTime" },
        { label: "起始编码", prop: "intervalStart" },
        { label: "截止编码", prop: "intervalEnd" },
        { slotName: "disCompanyNum" },
        { label: "已分配数量", prop: "disExpress" },
        { label: "已使用数量", prop: "usedNum" },
        { label: "生码人员", prop: "adminName" },
        { slotName: "printingDate" },
        { label: "备注", prop: "remark" },
        { slotName: "operation" },
      ],
      activaColumns: [
        { label: "商家名称", prop: "companyName" },
        { label: "省市区", prop: "fullAddress" },
        { label: "地址", prop: "address" },
        { label: "门店数量", prop: "storeNum" },
        { label: "门店平均剩余码(张)", prop: "avgSurplusNum" },
        { slotName: "distributionNum" },
        { slotName: "operation" },
      ],
      businessColumns: [
        { label: "商家名称", prop: "companyName" },
        { label: "省市区", prop: "fullAddress" },
        { label: "地址", prop: "address" },
        { label: "门店数量", prop: "storeNum" },
        { label: "历史分配二维码", prop: "historyCode" },
        { label: "当前总剩余二维码", prop: "totalSurplusNum" },
        { label: "门店平均剩余", prop: "avgSurplusNum" },
        { label: "最近分配时间", prop: "latelyDistributionTime" },
      ],
      allocatedColumns: [
        { label: "商家名称", prop: "companyName" },
        { label: "省市区", prop: "fullAddress" },
        { label: "地址", prop: "address" },
        { label: "门店数量", prop: "storeNum" },
        { label: "本批次分配二维码", prop: "batchTotalExpress" },
        { label: "本批次总剩余二维码", prop: "batchTotalSurplus" },
        { label: "本批次门店平均剩余", prop: "batchAvgSurplus" },
        { label: "本批次最近分配时间", prop: "createTime" },
      ]
    };
  },
  created() {
    this.batchCodecd = this.$route.query.batchCode || ""
    this.handleCurrentChange();
  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.batchCodecd = newVal.batchCodecd
      this.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {

    // 表格切换页面
    handleCurrentChange(val) {
      this.loading = true
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        batchCode: this.SeachParams.batchCodecd || this.batchCodecd,
        startTime: this.SeachParams.startTimecd,
        endTime: this.SeachParams.endTimecd,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.$emit("handleCurrentChange", val);
      _api.barCodeList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
        }
        this.loading = false
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 已分配商家列表
    distributionList(){
      this.allocatedLoading = true
        const params = {
          batchCode: this.batchCode,
          companyName: this.companyName,
        }
        _api.distributionList(params).then(res=>{
          if(res.code===1){
            if(this.selectSortData.timeSort || this.selectSortData.numSort){
              if(this.filterFormData.sortType == 'time'){
                if(this.selectSortData.timeSort == 'asc'){
                  res.data.sort(this.compare('batchAvgSurplus',true))
                }else{
                  res.data.sort(this.compare('batchAvgSurplus',false))
                }
              }else{
                res.data.forEach(item => {
                  if(item.createTime){
                    item.createTime = Date.parse(item.createTime);
                  }
                })
                if(this.selectSortData.numSort == 'asc'){
                  res.data.sort(this.compare('createTime',true))
                }else{
                  res.data.sort(this.compare('createTime',false))
                }
                res.data.forEach(item => {
                  if(item.createTime){
                    item.createTime = Moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                  }
                })
              }
            }

            this.allocatedLoading = false
            this.assignedMerchantList = res.data
          }
        })
    },
    // 已分配商家弹窗显示操作
    handleShowUser(row) {
      console.log(row);
      this.allocatedVisible = true;
      this.batchCode = row.batchCode
      this.distributionList()
    },
    // 分配激活操作
    distributionBtn(row) {
      this.activaVisible = true;
      this.batchCode = row.batchCode
    },
    // 表格添加/修改备注操作
    handleBtn(row) {
      this.batchCode = row.batchCode
      if (row.remark) {
        this.remark = row.remark;
        this.dialogTitle = "修改备注";
      } else {
        this.remark = "";
        this.dialogTitle = "添加备注";
      }
      this.dialogVisible = true;
    },
    //弹窗取消
    handleClose() {
      this.dialogVisible = false;
      this.activaVisible = false;
      this.assignedMerchantList = []
      this.companyName = ""
      this.companyIdArr = []
    },
    allocatedClose() {
      this.allocatedVisible = false;
      this.assignedMerchantList = []
      this.selectSortData = {
        timeSort: '',
        numSort: ''
      }
      this.filterFormData = {
        sortType: 'time',
      }
    },
    // 表格添加/修改备注确定操作
    barCodeRemark(){
      const params = {
        batchCode:this.batchCode,
        remark:this.remark,
      }
      _api.barCodeRemark(params).then(res => {
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.handleCurrentChange()
          this.dialogVisible = false;
        }
      })
    },
    barCodeCompanyList(val) {
      if (val) {
        this.merchantPageNum = val;
        this.merchantPages.pageNum = val;
      } else {
        this.merchantPages.pageNum = 1;
      }
      this.businessLoading = true
      const baseRequest = {
        companyName: this.companyName,
        pageNum: this.merchantPages.pageNum,
        pageSize: this.merchantPages.pageSize,
      };
      _api.barCodeCompanyList(baseRequest).then(res => {
        if(res.code === 1){
          let merchantLists = res.data.records
          if(this.filterFormData.sort){
            merchantLists.forEach(item => {
            if(item.latelyDistributionTime){
                item.latelyDistributionTime = Date.parse(item.latelyDistributionTime);
              }
            })
            if(this.filterFormData.sort == 'asc'){
              merchantLists.sort(this.compare('latelyDistributionTime',true))
            }else{
              merchantLists.sort(this.compare('latelyDistributionTime',false))
            }
            merchantLists.forEach(item => {
              if(item.latelyDistributionTime){
                item.latelyDistributionTime = Moment(item.latelyDistributionTime).format('YYYY-MM-DD HH:mm:ss')
              }
            })
          }

          let _rows = []
          console.log(this.assignedMerchantList);
          this.merchantList = res.data.records
          this.merchantList.forEach(item => {
            if (this.assignedMerchantList.some(ele => ele.companyId === item.companyId)) {
              _rows.push(item)
              this.companyIdArr.push(item.companyId)
            }
            return item
          })
          this.toggleSelection(_rows)
          this.merchantPages.pageNum = res.data.current
          this.merchantPages.total = res.data.total
          this.businessLoading = false
          
          // this.toggle(res.data.records)
        }
      })
    },
    // 添加待分配激活商家
    addUnDistributionCompany() {
      if(!this.companyIdArr.length){
        this.$message.error('至少选择一个商家')
        return false
      }
      _api.addUnDistributionCompany({companies:this.companyIdArr}).then(res => {
        if(res.code === 1){
          this.$message.success("操作成功");
          this.assignedMerchantList = res.data
          this.addVisible = false
          this.volumeNo = []
        }
      })
    },
    // 确定分配
    distribution(){
      let assignedMerchantList = this.assignedMerchantList
      let flag = true
      let distributions = []
      assignedMerchantList.forEach(item => {
        if(item.distributionNum){
          let barCodeArr = []
          item.barCodeList.forEach(ele => {
            barCodeArr.push(ele.barCode)
          })
          distributions.push({
            barCodeList: barCodeArr,
            companyId: item.companyId
          })
        }else{
          flag = false
        }
      })
      if(!flag){
        this.$message.error("请先给全部已添加商家绑定条码");
        return false
      }
      this.activaloading = true
      let params = {
        batchCode: this.batchCode,
        distributions
      }
      _api.distribution(params).then(res => {
        if(res.code === 1){
          this.activaloading = false
          this.$message.success("恭喜！二维码已全部分配完成");
          this.activaVisible = false
          this.assignedMerchantList = []
          this.companyIdArr = []
          this.handleCurrentChange()
        }
      })
    },
    //弹窗确定
    handleSuccess() {
      if(this.dialogVisible){
        this.barCodeRemark()
      }
      if(this.addVisible){
        this.addUnDistributionCompany()
      }
      if(this.activaVisible && !this.addVisible){
        this.distribution()
      }
    },
    // 添加门店商家弹窗关闭
    addClosed() {
      this.addVisible = false;
    },
    // 添加门店商家弹窗显示
    addBusiness() {
      this.addVisible = true;
      this.barCodeCompanyList()
    },
    handleSelect(e){
      let arr = []
      e.forEach(item => {
        arr.push(item.companyId)
      });
      this.companyIdArr = this.companyIdArr.filter(item => {
				return !this.merchantList.some(ele => ele.companyId === item)
			})
      this.companyIdArr.push(...arr)
      console.log(this.companyIdArr);
    },
    // 门店商家查询
    searchBusiness() {
      console.log("查询", this.searchForm);
      if(this.addVisible){
        this.barCodeCompanyList()
      }else{
        this.distributionList()
      }
    },
    // 修改/添加备注弹窗取消关闭
    handleDialogClosed() {
      this.dialogVisible = false;
    },
    // 修改印刷完成时间
    printDate(batchCode,date){
      const params = {
        batchCode,
        date,
      }
      _api.printDate(params).then(res => {
        if (res.code === 1) {
          this.$message.success("操作成功");
          this.handleCurrentChange()
        }
      })
    },
    // 修改/添加印刷完成时间
    completionTimeFun(e) {
      console.log(e);
      if(e){
        this.printDate(this.batchCode,e)
      }
    },
    editTime(row) {
      this.batchCode = row.batchCode
    },
    importBusiness() {
      this.importVisible = true;
    },
    // 关闭批量导入弹窗
    importClosed() {
      this.importVisible = false;
    },
    // 上传文件
    uploadFile(params) {
      console.log("uploadFile", params);
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      _api.barCodeImport(formData).then((res) => {
        console.log("_RequestUploads_", res);
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: '导入成功',
          });
          this.assignedMerchantList = res.data
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
      });
    },
    importSuccess() {
      this.$refs.upload.submit();
      this.importVisible = false;
      this.$refs.upload.clearFiles()
    },
    // 绑定弹窗显示
    bindingBtn(row) {
      this.companyId = row.companyId
      this.bindingVisible = true
      this.number = row.distributionNum || 1
      if(row.distributionNum){
        console.log(row.barCodeList);
        this.bindingList = []
        row.barCodeList.forEach(item => {
          this.bindingList.push({
            value:item.barCode,
            tip:'',
            isSuccess:true,
            isEdit: true,
            volumeInfo: item.volumeInfo
          })
        })
        console.log(this.bindingList);
      }
      // this.$nextTick(() => {
      //   this.$refs.input.focus()
      // })
    },
    // 绑定弹窗隐藏
    bindingClose() {
      this.bindingVisible = false
      this.number = 1
      console.log(this.bindingList);
      this.bindingList = [{
        value:"",
        tip:"未验证",
        isSuccess:null,
      }]

    },
    // 分配份数加
    plusNum() {
      if(this.number>=15){
        return
      }
      let item = {
        value:"",
        tip:"未验证",
        isSuccess: null
      }
      this.bindingList.push(item)
      this.number++
    },
    // 分配份数删除
    bindingReduce(index) {
      this.volumeNo.forEach((item,i) => {
        if(this.bindingList[index].volumeInfo){
          if(this.bindingList[index].volumeInfo.volumeNo == item){
            this.volumeNo.splice(i,1)
          }
        }
      })
      this.bindingList.splice(index,1)
      this.number = this.bindingList.length
    },
    qcInput(e,item,index) {
      let listIndex = this.bindingList.length-1
      if(e.length == 10){
        this.bindingList.forEach((ele,i) => {
          if(ele.value == e){
            if(i<listIndex){
              for (let num = 1; num < listIndex-i+1; num++) {
                this.bindingList[i+num].value = Number(e)+(500*num)
                this.check(this.bindingList[i+num],i+num)
              }
            }
          }
        })
        this.check(item,index)
      }
    },
    // 二维码批次校验
    check(item,index) {
      console.log(this.volumeNo);
      console.log(item);
      if(item.volumeInfo){
        this.volumeNo.forEach((ele,i) => {
          if(item.volumeInfo.volumeNo == ele){
            this.volumeNo.splice(i,1)
          }
        })
      }
      _api.barCodeValid({barCode:item.value,batchCode:this.batchCode}).then(res => {
        if(res.code === 1){
          let flag = false
          if(item.isSuccess == null || item.isSuccess == '00'){
            this.volumeNo.forEach(ele => {
              if(ele === (res.data.volumeInfo?res.data.volumeInfo.volumeNo:"")){
                this.bindingList[index].tip = '该二维码所在区间已经校验'
                this.bindingList[index].isSuccess = '00'
                flag = true
              }
            })
          }
          
          if(flag){
            return false
          }
          console.log(this.volumeNo);
          this.bindingList[index].tip = res.data.cause
          this.bindingList[index].volumeInfo = res.data.volumeInfo
          if(res.data.isSuccess){
            this.bindingList[index].isSuccess = '01'
            this.volumeNo.push(res.data.volumeInfo.volumeNo)
            let inputArray = document.getElementsByClassName("qcInput" );  //通过class去获取
            if(inputArray[index + 1] && inputArray[index + 1].childNodes){
              let nextInput = inputArray[index + 1].childNodes;
              nextInput[1].focus();
            }
          }else{
            this.bindingList[index].isSuccess = '00'
          }
        }
      })
    },
    // 确认绑定操作
    bindingSuccess() {
      let bindingList = this.bindingList
      let flagValue = true
      let flagIsSuccess = true
      let barCodeList = []
      console.log(bindingList);
      bindingList.forEach(item => {
        if(item.isSuccess == true){
          item.isSuccess = '01'
        }
        if(!item.value){
          item.tip = '请补全二维码'
          item.isSuccess = '00'
          flagValue =  false
        }else if(item.isSuccess !== '01'){
          flagIsSuccess =  false
        }
        console.log(item);
        barCodeList.push({
          barCode: item.value,
          volumeInfo: item.volumeInfo
        })
      })
      console.log(flagIsSuccess,flagValue);
      if(!flagIsSuccess || !flagValue){
        this.$message.error("有二维码校验错误或没有校验");
        return false
      }
      this.bindingList = [{
        value:"",
        tip:"未验证",
        isSuccess:null,
      }]
      this.bindingVisible = false
      this.assignedMerchantList.forEach(item => {
        if(item.companyId == this.companyId){
          item.distributionNum = this.number
          item.barCodeList = barCodeList
        }
      })
      
      this.number = 1
    },
    distributionClose() {
      let falg = true
      console.log(123456);
      if(this.assignedMerchantList.length){
        this.assignedMerchantList.forEach(ele => {
          if(ele.distributionNum){
            falg = false
            this.$confirm('数据未保存，确认关闭吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.activaVisible = false
            this.assignedMerchantList = []
          })
          }
        })
      }
      if(falg){
        this.activaVisible = false
      }
    },
    bindingClosed() {
      let falg = true
      this.bindingList.forEach(item => {
        if(item.value){
          falg = false
          this.$confirm('数据未保存，确认关闭吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.bindingList.forEach(val => {
              this.volumeNo.forEach((ele,i) => {
                if(val.volumeInfo && !val.isEdit){
                  if(val.volumeInfo.volumeNo == ele){
                    this.volumeNo.splice(i,1)
                  }
                }
              })
            })
            
            this.bindingVisible = false
            this.number = 1
            this.bindingList = [{
              value:"",
              tip:"未验证",
              isSuccess:null,
            }]
          })
        }
      })
      if(falg){
        this.bindingVisible = false
        this.bindingList = [{
          value:"",
          tip:"未验证",
          isSuccess:null,
        }]
      }
    },
    handleSort (item) {
      if (this.filterFormData.sortType === 'time') {
        this.selectSortData.timeSort = item
        this.filterFormData.sort = this.selectSortData.timeSort
        if(this.addVisible){
          this.barCodeCompanyList(this.merchantPageNum)
        }
      }
      if (this.filterFormData.sortType === 'num') {
        this.selectSortData.numSort = item
        this.filterFormData.sort = this.selectSortData.numSort
      }
      if(!this.addVisible){
        this.distributionList()
      }
    },
    changeSort (e) {
      if (e === 'time') {
        this.selectSortData.timeSort = 'desc'
        this.selectSortData.numSort = ''
        this.filterFormData.sort = this.selectSortData.timeSort
      }
      if (e === 'num') {
        this.selectSortData.numSort = 'desc'
        this.selectSortData.timeSort = ''
        this.filterFormData.sort = this.selectSortData.timeSort
      }
      this.handleSort()
    },
    download() {
      const link = document.createElement("a");
      link.href = "/static/商家导入模板.xlsx";
      link.setAttribute("download", "商家导入模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 回显显示效果
		toggleSelection (rows) {
      console.log(rows);
			Promise.resolve().then(() => {
				if (rows && rows.length) {
					rows.forEach(row => {
            console.log(row);
						this.$refs.multipleTable.setSelection(row)
					})
				} else {
					this.$refs.multipleTable.clearSelection()
				}
			})
		},
    compare(property,data){
      return function(a,b){
          var value1 = a[property];
          var value2 = b[property];
          if(data){
            return value2 - value1;
          }else{
            return value1 - value2;
          }
      }
    },
    onUpload(e) {
			let TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(),'63e42c2444e94c1ebca21d30d2aa39a5'),localStorage.getItem('token') || '')
			this.headers.TOKEN = TOKEN
		},
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .text-btn {
    text-decoration: underline;
  }
  .selectSort {
    display: flex;
    .el-radio {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      /deep/.el-radio__label{
        display: flex;
        align-items: center;
      }
    }
  }
  .time-btn {
    margin-bottom: 0 !important;
    text-decoration: underline;
    position: relative !important;
    .el-date-editor {
      background-color: darkblue !important;
      width: 30px !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      opacity: 0;
    }
  }
  /deep/.el-textarea__inner {
    height: 150px !important;
  }
  .activa-dialog {
    .explain {
      color: #0981ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .activa-table {
      margin-top: 20px;
      .activa-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-left: 8px solid #3399ff;
        padding: 0 10px;
        box-sizing: border-box;
        .title1 {
          font-weight: bold;
          color: #333;
          margin-right: 10px;
        }
        .title2 {
          color: #ff687b;
          margin-right: 10px;
        }
      }
    }
    .el-table-container{
      margin-top: 30px !important;
    }
  }
  .allocated-business-dialog {
    .operation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .search {
        display: flex;
        align-items: center;
        .search-item {
          margin-right: 30px;
        }
        .search-input {
          width: 240px;
          /deep/.el-input__inner {
            height: 30px;
          }
        }
      }
    }
    .el-table-container{
      margin-bottom: 30px !important;
    }
  }
  .add-business-dialog {
    .operation {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      .search {
        display: flex;
        align-items: center;
        .search-item {
          margin-right: 30px;
        }
        .search-input {
          width: 240px;
          /deep/.el-input__inner {
            height: 30px;
          }
        }
      }
    }
  }
  .import-business-dialog {
    .step {
      .step-title {
        color: #0981ff;
        margin-bottom: 10px;
        padding-left: 6px;
        border-left: 3px solid;
        border-radius: 1.5px;
      }
      .step-content,
      .step2-content {
        margin-bottom: 26px;
      }
      .step2-content > div {
        display: flex;
      }
      .step2-content {
        .upload-demo {
          display: flex;
          justify-content: start;
          .el-upload-list {
            width: 200px;
          }
        }
      }
      .tip {
        margin-bottom: 12px;
        .tip-title {
          color: #666666;
          margin-right: 12px;
        }
        .tip-content {
          color: #ff687b;
          font-size: 12px;
        }
      }
    }
  }
  .binding-dialog{
    /deep/.el-input-number__decrease{
      display: none;
    }
    /deep/.el-input__inner{
      padding-left: 0;
    }
    .binding-num{
      display: flex;
      align-items: center;
      /deep/.el-input__inner{
        width: 52px;
        height: 30px;
        padding-left: 18px;
        background-color: #fff;
        color: #333;
      }
      /deep/.el-input-group{
        width: 70px;
      }
      /deep/.el-input-group__append{
        padding: 0 10px;
        color: #333;
      }
    }
    .tip{
      margin: 15px 0;
      color: #FF687B;
    }
    .binding-input{
      margin-bottom: 20px;
      width: 516px;
      max-height: 400px;
      overflow-y: scroll;
      /deep/.el-input__suffix{
        right: 1px;
        top: 1px;
        .el-button{
          height: 32px;
        }
      }
      /deep/.el-input__inner{
        height: 34px;
      }
      /deep/.el-input__inner{
        padding-left: 10px;
      }
      .binding-input-flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/.el-input{
          margin-right: 10px;
        }
      }
      .binding-input-item{
        padding-bottom: 15px;
        .checks{
          color: #A1A4AF;
        }
        .success{
          color: #80C269;
        }
        .fail{
          color: #f83232;
        }
      }
      .binding-reduce:hover{
        cursor:pointer;
      }
    }
  }
}
</style>
